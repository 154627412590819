import { ref } from "vue";

export default {
  tag: "en-US",
  label: "EN",
  labelFull: "English (United States)",
  flag: "israel", // todo: get and change this to US
  messages: {
    message: {},
  },
  datetimeFormats: {
    short: {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    },
    dateWithTime: {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    hoursAndMinutes: {
      hour: "2-digit",
      minute: "2-digit",
    },
    shortDayAndMonth: {
      day: "2-digit",
      month: "2-digit",
    },
    fullYear: {
      year: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    full: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    },
  },
  numberFormats: {
    currency: {
      style: "currency",
      currency: ref(config.ecommerce.currency.default).value,
      notation: "standard",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  useTitleCase: true,
  /*
  translateNumber(instance, value, type, local, options) {
    return instance.$n(value, type, local, options);
  },
  */
};
