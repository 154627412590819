import { useStore } from "vuex";

export default (props, storeArgument) => {
  let store = storeArgument;
  if (!store) {
    store = useStore();
  }

  if (!store) {
    error(
      "ERROR: useCurrency requires access to store. Either call it in a setup context, or provide the store as a second argument. Troubles will most likely follow."
    );
  }

  const convert = (amount, from, to) => {
    if (typeof to !== "string") {
      to = store.getters["currency/code"];
    }
    let exchangeRates = store.getters["currency/exchangeRates"];
    let ratioFromBase = exchangeRates[from.toUpperCase()].value;
    let ratioToBase = exchangeRates[to.toUpperCase()].value;

    return (amount * ratioToBase) / ratioFromBase;
  };

  return { convert };
};
